import { FC } from 'react'

import { LogoutButton } from '../Common'

import { DailyNotification } from './DailyNotification'
import { DeveloperOptions } from './DeveloperOptions'
import { UserInfo } from './UserInfo'

export const SettingsScreen: FC = () => {
    return (
        <div className='grid grid-rows-[repeat(3,min-content)] gap-4 px-4 py-2'>
            <UserInfo />
            <DailyNotification />
            <LogoutButton />
            <DeveloperOptions />
        </div>
    )
}
