import { useRouter } from 'next/router'
import { ComponentProps, FC } from 'react'

import { backend } from '@closer/api'
import { LocalStorageKey } from '@closer/watermelondb'
import { TenantUserAuth } from '@closer/types'

import { composeUrlState } from '../../hooks/router/useValidRoute'
import { localStorage } from '../../pages/_app'

const labelStyle = 'text-sm'

export const DeveloperOptions: FC = () => {
    if (process.env['NEXT_PUBLIC_APP_ENV'] === 'Production') {
        return null
    }

    return (
        <div className='grid grid-rows-[repeat(3,min-content)] gap-2'>
            <div className='pb-1 border-b'>
                <h2 className='text-xl font-semibold'>Developer Options</h2>
            </div>
            <div className='grid grid-cols-[min-content,1fr] gap-2 py-2'>
                <p className={`${labelStyle} text-neutral-400`}>Environment</p>
                <p className={`${labelStyle}`}>{process.env['NEXT_PUBLIC_APP_ENV']}</p>
                <p className={`${labelStyle} text-neutral-400`}>Backend</p>
                <p className={`${labelStyle}`}>{backend.api.getUri()}</p>
            </div>
            <WhatsappBotButton />
        </div>
    )
}

const WhatsappBotButton: FC = () => {
    const router = useRouter()
    const botProps: ComponentProps<'button'> = {
        className: 'py-1 rounded font-bold text-[#65BA8E] bg-neutral-100',
        onClick: async () => {
            const tenantUser: TenantUserAuth | null = await localStorage.getItem(LocalStorageKey.CLOSER_AUTH)

            if (!tenantUser?.matrixAccount?.roomIds.whatsapp) {
                return
            }

            router.push(composeUrlState({ f: 'RoomList', x: 'View', r: tenantUser.matrixAccount.roomIds.whatsapp }))
        }
    }

    return <button {...botProps}>Whatsapp Bridge Bot</button>
}
