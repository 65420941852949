import { NextPage } from 'next'
import { setUser } from '@sentry/nextjs'
import { useDatabase } from '@nozbe/watermelondb/hooks'
import { Component, useEffect } from 'react'

import { MatrixAccount } from '@closer/types'
import { sideMenuItem } from '@closer/headless-components/components'
import { useAppSelector } from '@closer/redux-storage'

import { api } from '@closer/api'
import { SettingsScreen } from '../../components/Settings'
import { Stub } from '../../components/Common/Stub'
import { SyncedContactListScreen } from '../../components/ContactSync/SyncedContactListScreen'
import { ThreeColumns } from '../../components/Common/ThreeColumns'
import { useMutation } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import {
    CommandPalette,
    InitialSyncScreen,
    LoadingSpinner,
    ReminderEditor,
    RemindersScreen,
    RoomListScreen,
    RoomScreen,
    ScheduleEditor,
    ScheduleList,
    SideMenu
} from '../../components'
import { featureExtension, featureName, getExtensionKey, useValidRoute } from '../../hooks/router/useValidRoute'

class Feature extends Component {
    static [sideMenuItem.RoomList] = RoomListScreen
    static [sideMenuItem.Reminders] = RemindersScreen
    static [sideMenuItem.ScheduledMessages] = ScheduleList
    static [sideMenuItem.Settings] = SettingsScreen
    static [featureName.Archive] = RoomListScreen
    static [featureName.Contact] = SyncedContactListScreen
}

class ExtensionScreen extends Component {
    static [featureExtension.RoomList.View] = RoomScreen
    static [featureExtension.RoomList.Forward] = RoomScreen
    static [featureExtension.RoomList.NewReminder] = ReminderEditor
    static [featureExtension.RoomList.EditReminder] = ReminderEditor
    static [featureExtension.RoomList.NewSchedule] = ScheduleEditor
    static [featureExtension.RoomList.EditSchedule] = ScheduleEditor

    static [featureExtension.Archive.View] = RoomScreen

    static [featureExtension.Reminders.ViewRoom] = RoomScreen
    static [featureExtension.Reminders.Create] = ReminderEditor
    static [featureExtension.Reminders.Edit] = ReminderEditor

    static [featureExtension.ScheduledMessages.Create] = ScheduleEditor
    static [featureExtension.ScheduledMessages.Edit] = ScheduleEditor
}

const CloserApp: NextPage = () => {
    const { f, x } = useValidRoute()
    const extensionKey = getExtensionKey(f, x)
    // FIXME: fallback to RoomList produces hydration error
    const ActiveFeature = Feature[f || sideMenuItem.RoomList]
    const ActiveExtension = extensionKey ? ExtensionScreen[extensionKey] : Stub
    const database = useDatabase()
    const router = useRouter()
    const [tenantUser, finishedSync, loginType, authIsLoaded] = useAppSelector(state => [
        state.closerUser.tenantUser,
        state.matrix.isFinishInitialSync,
        state.matrix.loginType,
        state.matrix.authIsLoaded
    ])
    const { mutate, isLoading, data } = useMutation<MatrixAccount | undefined, any, { id: string }, any>([], async ({ id }) => {
        const matrixAccount = await api.matrixAccount.getConnectStatus(id)
        return matrixAccount
    })

    useEffect(() => {
        const setSentryAndInit = async () => {
            // const closeAuth: string | void = await database.localStorage.get(LocalStorageKey.CLOSER_AUTH)

            if (tenantUser) {
                // const user: TenantUserAuth = JSON.parse(closeAuth)
                // user && setUser({ email: tenantUser.email })
                setUser({ email: tenantUser.email })

                if (tenantUser.matrixAccount) {
                    mutate({ id: tenantUser.matrixAccount.id })
                }
            }
        }
        setSentryAndInit()
    }, [database.localStorage, mutate, tenantUser])

    useEffect(() => {
        const initRoute = () => {
            if (loginType === 'password' && !finishedSync && tenantUser) {
                return
            } else if (data) {
                if (!data.connection) {
                    router.push('/disconnect')
                }
            }
        }
        initRoute()
    }, [data, finishedSync, loginType, router, tenantUser])

    useEffect(() => {
        if (tenantUser === null) {
            router.push('/login')
        }
    }, [router, tenantUser])

    if (tenantUser === undefined || !data || !authIsLoaded || isLoading) {
        return <LoadingSpinner />
    }

    if (loginType === 'password' && !finishedSync && tenantUser) {
        return <InitialSyncScreen />
    }

    if (!data.connection) {
        return null
    }

    return (
        <CommandPalette>
            <ThreeColumns>
                <SideMenu />
                <ActiveFeature />
                <ActiveExtension />
            </ThreeColumns>
        </CommandPalette>
    )
}

export default CloserApp
