import { ComponentProps, FC, useMemo, useRef, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { api } from '@closer/api'
import { R, TenantUserSetting } from '@closer/types'

import { hiCheck, hiXMark, LoadingSpinner, mdClockEdit, Svg } from '../Common'

const [_, timezone, ...localeParts] = new Date().toTimeString().split(/\s/)
const timezoneString = `${timezone} ${localeParts.join(' ')} `
const timezoneOffsetInMinutes = new Date().getTimezoneOffset()

export const DailyNotification: FC = () => {
    const timeRef = useRef<HTMLInputElement>(null)
    const queryClient = useQueryClient()
    const [isEditingTime, setIsEditingTime] = useState(false)
    const { data, error, isLoading } = useQuery([R.TENANT_USER_SETTINGS], api.tenantUserSettings.read)
    const { mutate: updateNotificationTime, isLoading: isMutating } = useMutation<TenantUserSetting, Error, string>(
        timeString => {
            const [localHour, localMinute] = timeString.split(':').map(digit => Number(digit))
            const utcTimeInMinutes = localHour * 60 + localMinute + timezoneOffsetInMinutes
            const hour = Math.floor(utcTimeInMinutes / 60)
            const minute = utcTimeInMinutes % 60
            const newTime = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`

            if (newTime === data?.dailyNotificationTime) {
                return Promise.resolve(data)
            }

            return api.tenantUserSettings.update({ dailyNotificationTime: newTime })
        },
        {
            onSuccess: async () => {
                await queryClient.refetchQueries([R.TENANT_USER_SETTINGS])
                setIsEditingTime(false)
            }
        }
    )
    const notificationTime = useMemo(() => {
        if (!data?.dailyNotificationTime) {
            return ''
        }

        const [utcHour, utcMinute] = data.dailyNotificationTime.split(':').map(digit => Number(digit))
        const localTimeInMinutes = utcHour * 60 + utcMinute - timezoneOffsetInMinutes
        const hour = Math.floor(localTimeInMinutes / 60)
        const minute = localTimeInMinutes % 60

        return `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`
    }, [data?.dailyNotificationTime])
    const pointerEvents = `transition-opacity  ${!data || isLoading || isMutating ? 'opacity-50 pointer-events-none' : ''}`
    const timeProps: ComponentProps<'input'> = {
        type: 'time',
        disabled: isLoading || isMutating,
        defaultValue: notificationTime,
        className: `border-none p-0 text-lg px-2 bg-neutral-100 rounded focus:ring-0 ${pointerEvents}`
    }

    return (
        <div>
            <div className='grid grid-cols-[1fr,repeat(2,min-content)] gap-1 pb-1 border-b'>
                <h2 className='text-xl font-semibold'>Daily Notification Time</h2>
                {isEditingTime ? (
                    <>
                        <button className={pointerEvents} onClick={() => timeRef.current && updateNotificationTime(timeRef.current.value)}>
                            <Svg d={hiCheck} fill='none' stroke='currentColor' className='w-5 h-5 text-green-500' />
                        </button>
                        <button className={pointerEvents} onClick={() => setIsEditingTime(false)}>
                            <Svg d={hiXMark} fill='none' stroke='currentColor' className='w-5 h-5 text-red-500' />
                        </button>
                    </>
                ) : (
                    <button className={`col-start-3 ${pointerEvents}`} onClick={() => setIsEditingTime(true)}>
                        <Svg d={mdClockEdit} fill='currentColor' className='w-6 h-6 text-[#65BA8E]' />
                    </button>
                )}
            </div>
            {isLoading ? (
                <div className='py-2'>
                    <LoadingSpinner size={4} />
                </div>
            ) : error ? (
                <p>{JSON.stringify(error)}</p>
            ) : (
                <div className='col-span-2 py-2'>
                    {isEditingTime ? <input {...timeProps} ref={timeRef} /> : <p className='text-lg'>{notificationTime}</p>}
                    <p className='text-xs text-neutral-400'>{timezoneString}</p>
                </div>
            )}
        </div>
    )
}
