import * as Sentry from '@sentry/nextjs'

import { FC } from 'react'
import { IoChevronBackOutline } from 'react-icons/io5/'
import { IStateEvent } from 'matrix-js-sdk'
import { useRouter } from 'next/router'
import { InfiniteData, useQueryClient } from '@tanstack/react-query'

import { chatService } from '@closer/matrix'
import { isMessage } from '@closer/utils'
import { EventContent, IMessageContent, Q, RoomEvent } from '@closer/types'
import { SyncedContactRootProps as HeadlessProps, SyncedContactListRoot as HeadlessScreen } from '@closer/headless-components/components'

import { composeUrlState, useValidRoute } from '../../hooks/router/useValidRoute'

import { LoadingSpinner } from '../Common/LoadingSpinner'
import { Stub } from '../Common/Stub'

import { ContactSearchBar } from './ContactSearchBar'
import { ContactsList } from './ContactsList'

export const SyncedContactListScreen: FC = () => {
    // TODO: setup web FCM
    // const { data: token } = useQuery(['DEVICE_TOKEN'], async () => {
    //     const remoteDeviceRecord = await localStorage.getItem(LocalStorageKey.REMOTE_DEVICE_RECORD)
    //     const { deviceToken } = remoteDeviceRecord
    //     return deviceToken || 'web-browser-token'
    // })
    const token = 'web-browser-token'
    const router = useRouter()
    const queryClient = useQueryClient()

    const { f, x, r, isValid } = useValidRoute({ f: _f => /^(RoomList|Contact)$/.test(_f), x: _x => /^(Forward|VCard)$/.test(_x), r: _r => _r.length > 0, m: _m => true })

    if (f !== 'Contact' && !isValid) {
        return <Stub label='Invalid Route' />
    }

    let roomId
    let event

    if (x === 'Forward') {
        const resource = JSON.parse(r)
        roomId = resource[0]
        const eventId = resource[1]
        console.log(roomId, eventId)
        const timelines = queryClient.getQueryData<InfiniteData<{ start: string; end: string; state: IStateEvent[]; chunk: RoomEvent<EventContent>[] } | undefined>>([Q.ROOM_TIMELINES, roomId])
        if (!timelines || !timelines.pages || timelines.pages.length === 0) {
            return <Stub label='Invalid Route' />
        }
        const events = timelines.pages.reduce((acc, page) => {
            if (!page) {
                return []
            }
            return [...acc, ...page.chunk]
        }, [] as RoomEvent<EventContent>[])
        const messageEvents = events.filter(_event => isMessage(_event, ['m.room.message']) && _event.event_id === eventId) as RoomEvent<IMessageContent>[]
        if (messageEvents.length === 0) {
            return <Stub label='Invalid Route' />
        }
        event = messageEvents[0]
    } else if (x === 'VCard') {
        roomId = r
    }

    const context = f === 'Contact' ? 'Start New Chat' : f === 'RoomList' && x === 'Forward' ? 'Forward Message' : 'Pick Contact Card'
    const headlessProps: HeadlessProps = {
        context,
        event,
        roomId,
        deviceToken: token,
        render: ({ newContactsAvailable, isLoading, contacts, searchTerm, setSearchTerm, downloadContacts /*, StartNewChat, ForwardMessage, PickContactCard*/ }) => {
            let contactList = <Stub label='Invalid Route' />

            // disabled newChat and forward for current

            // if (f === 'Contact' && !x) {
            //     contactList = <ContactsList contacts={contacts} onPress={StartNewChat} disabled={isLoading} />
            // } else if (f === 'Contact' && x === 'Forward') {
            //     contactList = <ContactsList contacts={contacts} onPress={ForwardMessage} disabled={isLoading} />
            // } else if (f === 'Contact' && x === 'VCard') {
            //     contactList = <ContactsList contacts={contacts} onPress={PickContactCard} disabled={isLoading} />
            // }
            contactList = <ContactsList contacts={contacts} onPress={() => {}} disabled={isLoading} />

            return (
                <div className='container flex flex-col overflow-hidden'>
                    <div>
                        <button onClick={() => router.back()} className='grid grid-cols-[min-content,_min-content] items-center h-10 bg-white'>
                            <IoChevronBackOutline />
                            Back
                        </button>
                    </div>
                    <div>
                        <ContactSearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
                    </div>
                    <div>
                        <div className='flex flex-row justify-center items-center h-10 bg-[#00000010] text-[#56BA8E] relative cursor-pointer' onClick={downloadContacts}>
                            <div className='mx-auto'>download latest contact</div>
                            {newContactsAvailable && <div className='w-2 h-2 rounded-full bg-red-500 absolute top-4 right-4' />}
                        </div>
                    </div>
                    {isLoading && <LoadingSpinner />}
                    <div className='overflow-y-auto'>{contactList}</div>
                </div>
            )
        },
        onAlert: alertMessage => {
            console.log(alertMessage)
        },
        onCaptureError: error => {
            Sentry.captureException(error)
            console.log(error)
        },
        onOpenRoom: targetRoomId => {
            console.log('Room', { roomId: targetRoomId })
            router.push(composeUrlState({ f: 'RoomList', x: 'View', r: targetRoomId }))
        },
        onSaveVCard: async cardInfo => {
            // FIXME: construct vCard
            // const card = VCard()
            // if (cardInfo.cellPhone) {
            //     card.cellPhone = cardInfo.cellPhone
            // }
            // if (cardInfo.avatarUrl) {
            //     card.photo.attachFromUrl(cardInfo.avatarUrl, cardInfo.avatarAttachType)
            // }
            // card.firstName = cardInfo.firstName
            // card.formattedName = cardInfo.formattedName
            const blob = new Blob([''], { type: 'text/vcard' })
            return blob
        },
        onLeave: () => router.back(),
        onDownloadError: errorMessage => {
            console.log(errorMessage)
        },
        onDownloadSuccess: () => chatService.updateList('Contact Sync')
    }

    return <HeadlessScreen {...headlessProps} />
}
