import { FC } from 'react'

import { useFPSMetric } from '@closer/headless-components/hooks'

interface FPSMonitorProps {
    visible: boolean
}

export const FPSMonitor: FC<FPSMonitorProps> = ({ visible }) => {
    const { fps, average } = useFPSMetric()

    if (!visible) {
        return null
    }
    return (
        <div className='text-[10px] grid justify-center'>
            Current/Average FPS: {fps.toFixed(1)} / {average.toFixed(1)}
        </div>
    )
}
