import UrlPattern from 'url-pattern'

import { animated, useSpring } from 'react-spring'
import { CSSProperties, FC, PointerEvent, PropsWithChildren, useEffect, useRef, useState } from 'react'

import { sideMenuItemNames } from '@closer/headless-components/components'
import { useAppSelector } from '@closer/redux-storage'

import { useValidRoute } from '../../hooks/router/useValidRoute'

import { FeatureOptions } from './FeatureOptions'
import { FPSMonitor } from './FPSMonitor'
import { LoadingSpinner } from './LoadingSpinner'
import { Svg } from './Svg'
import { useBackendSync } from './hooks'
import { LoadingModal, LoadingModalProps } from './LoadingModal'

const noOverflow = 'h-full overflow-hidden'
const gradient = 'linear-gradient(transparent, #00000080, transparent)'
const gradientMask: CSSProperties = { WebkitMaskImage: gradient, maskImage: gradient }

// we define the style as a static string and then extract the top and width style values to use in the calculations inside the component
// because Tailwind does not support dynamically constructed class names
const dividerStyle = 'hover:cursor-grab active:cursor-grabbing absolute top-[100px] h-[calc(100%_-_200px)] w-[10px] z-10 bg-[url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAACCAYAAABytg0kAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAABZJREFUeNpi2r9//38gYGAEESAAEGAAasgJOgzOKCoAAAAASUVORK5CYII=)]'
const dividerStyleExtraction = ['dividerMarginY', 'dividerWidth'] as const
const dividerStyleExtractionPattern = new UrlPattern(/top-\[(\d+)px\].*w-\[(\d+)px\]/, [...dividerStyleExtraction])
const matchResult = dividerStyleExtractionPattern.match(dividerStyle) as Record<typeof dividerStyleExtraction[number], string>

const dividerWidth = Number(matchResult.dividerWidth)
const dividerMarginY = Number(matchResult.dividerMarginY)
const leftColumnDefaultWidth = 225
const midColumnDefaultWidth = 300

export const ThreeColumns: FC<PropsWithChildren> = ({ children }) => {
    const { f, x } = useValidRoute()
    const authIsLoaded = useAppSelector(state => state.matrix.authIsLoaded)
    const matrixIsReady = useAppSelector(state => state.matrix.matrixIsReady)
    const matrixIsSynced = useAppSelector(state => state.matrix.matrixIsSynced)
    const matrixStateIsCorrupted = useAppSelector(state => state.matrix.matrixStateIsCorrupted)
    const matrixIsRefreshing = useAppSelector(state => state.matrix.matrixIsRefreshing)
    const loadingModalProps: LoadingModalProps = {
        visible: !matrixIsSynced || matrixStateIsCorrupted || matrixIsRefreshing,
        title: matrixStateIsCorrupted ? 'State refresh needed, auto-updating' : matrixIsRefreshing ? 'Refreshing' : matrixIsReady ? 'Syncing' : 'Loading'
    }

    const [{ midColumnWidth }, updateSpring] = useSpring(() => ({
        midColumnWidth: midColumnDefaultWidth,
        config: { duration: 0 }
    }))
    const [showLeftColumn, setShowLeftColumn] = useState(false)
    const [isResizing, setIsResizing] = useState(false)
    const rightColumnRef = useRef<HTMLDivElement>(null)
    const dividerRef = useRef<HTMLDivElement>(null)
    const leftColumnWidth = showLeftColumn ? leftColumnDefaultWidth : 0
    // FIXME: figure out the type of animated props
    const containerProps = {
        style: { gridTemplateColumns: `${leftColumnWidth}px 1fr` },
        className: `grid transition-[grid-template-columns] select-text ${noOverflow}`,
        onPointerMove: (evt: PointerEvent<HTMLDivElement>) => {
            if (!isResizing) {
                return
            }

            const maxCursorX = innerWidth - midColumnDefaultWidth
            const maxCursorY = innerHeight - dividerMarginY

            if (dividerRef.current) {
                const maxDividerx = maxCursorX - dividerWidth
                const minClampedDividerX = Math.max(midColumnDefaultWidth + leftColumnWidth, evt.clientX)
                const effectiveDividerX = Math.min(maxDividerx, minClampedDividerX) - leftColumnWidth

                dividerRef.current.style.left = `${effectiveDividerX}px`
                updateSpring({ midColumnWidth: effectiveDividerX })
            }

            if (evt.clientX < midColumnDefaultWidth || evt.clientX > maxCursorX || evt.clientY < dividerMarginY || evt.clientY > maxCursorY) {
                setIsResizing(false)
            }
        },
        onPointerUp: () => isResizing && setIsResizing(false)
    }
    const headerDisplayNames = {
        ...sideMenuItemNames,
        Archive: 'Archive',
        Contact: 'Contact'
    } as const

    // TODO: extract divider and right column into their own components to avoid mixing concerns
    useEffect(() => {
        if (dividerRef.current && authIsLoaded) {
            dividerRef.current.style.left = `${midColumnWidth.get()}px`
        }
    }, [authIsLoaded, midColumnWidth])

    useBackendSync()

    if (!authIsLoaded) {
        return <LoadingSpinner />
    }

    const featureHeader = !f || f === 'RoomList' ? (x === 'Forward' ? 'Forward to' : 'Home') : headerDisplayNames[f]

    return (
        <animated.div {...containerProps}>
            <div id='leftColumnContainer' className={noOverflow}>
                <FPSMonitor visible={process.env['NEXT_PUBLIC_APP_ENV'] !== 'Production'} />
                {children && Array.isArray(children) && children[0]}
            </div>
            <div id='midRightColumnContainer' className={`relative ${noOverflow}`}>
                <animated.div id='midColumnContainer' className={`absolute grid gap-2 grid-rows-[min-content_1fr] py-2 z-10 border-x bg-white ${noOverflow}`} style={{ width: midColumnWidth }}>
                    <div id='minColumnHeader' className='grid gap-x-2 grid-cols-[min-content_1fr_min-content] items-center h-8 px-2'>
                        <Svg stroke='#56BA8E' d='M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5' className='w-6 h-6 cursor-pointer' onClick={() => setShowLeftColumn(!showLeftColumn)} />
                        <p className='text-lg text-[#00000090] font-semibold'>{featureHeader}</p>
                        <LoadingModal {...loadingModalProps} />
                        <FeatureOptions />
                    </div>
                    {children && Array.isArray(children) && children[1]}
                </animated.div>
                <div id='midRightColumnDivider' style={gradientMask} className={dividerStyle} onPointerDown={() => setIsResizing(true)} ref={dividerRef} />
                <animated.div id='rightColumnContainer' className={`absolute top-0 right-0 w-full z-0 ${noOverflow}`} ref={rightColumnRef} style={{ paddingLeft: midColumnWidth }}>
                    {children && Array.isArray(children) && children[2]}
                </animated.div>
            </div>
        </animated.div>
    )
}
