import { FC, useMemo } from 'react'

import { ContactListItemProps, SyncedContactsRenderableData } from '@closer/headless-components/components'
// import { RoomInfo, useJoinedRoomIds, useMatrixRooms } from '@closer/headless-components/hooks'

// import { LoadingSpinner } from '../Common'

import { ContactListItem } from './ContactListItem'

interface ContactListProps extends Pick<ContactListItemProps, 'onPress'>, Pick<SyncedContactsRenderableData, 'contacts'> {
    disabled: boolean
}

export const ContactsList: FC<ContactListProps> = ({ contacts, disabled, onPress }) => {
//     const { roomIds, isLoading: isLoadingRoomIds } = useJoinedRoomIds()
//     const { rooms, isLoading: isLoadingRooms } = useMatrixRooms(roomIds || [], ({ hasLeft, isDirect }) => !!(!hasLeft && isDirect))

//     if (isLoadingRoomIds || isLoadingRooms || !roomIds || !rooms) {
//         return <LoadingSpinner />
//     }

//     return <ContactsListWithRooms {...props} rooms={rooms} />
// }

// interface ContactListWithRoomsProps extends ContactListProps {
//     rooms: Array<RoomInfo>
// }

// const ContactsListWithRooms: FC<ContactListWithRoomsProps> = ({ contacts, rooms, disabled, onPress }) => {
//     const roomsMap = useMemo(() => {
//         return Object.fromEntries(
//             contacts.map(({ matrixId }) => {
//                 const room = rooms.find(({ members }) => members.find(member => member.id === matrixId))
//                 return [matrixId, room]
//             })
//         )
//     }, [contacts, rooms])

    if (contacts.length === 0 && !disabled) {
        return <div className='flex grow flex-col justify-center items-center'>Try syncing your contacts</div>
    }

    return (
        <div className='divide-y'>
            {contacts.map(contact => {
                const listItemProps: ContactListItemProps = {
                    // roomInfo: roomsMap[contact.matrixId],
                    contact,
                    disabled,
                    onPress
                }
                return <ContactListItem key={contact.matrixId} {...listItemProps} />
            })}
        </div>
    )
}
