import { ComponentProps, FC } from 'react'

import { ContactSearchBarProps } from '@closer/headless-components/components/ContactSync'
import { label } from '@closer/types'

export const ContactSearchBar: FC<ContactSearchBarProps> = ({ searchTerm, setSearchTerm }) => {
    const inputProps: ComponentProps<'input'> = {
        id: label.textInput.SyncedContactSearchBar,
        placeholder: 'Search contacts',
        defaultValue: searchTerm,
        className: 'w-full ml-2 outline-none py-1 text-black bg-transparent',
        onChange: event => setSearchTerm(event.target.value)
    }
    return (
        <div className='grid grid-cols-[min-content_1fr] items-center mx-2 mb-1 px-2 rounded-lg overflow-hidden bg-neutral-100 text-[#00000075]'>
            <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' strokeWidth='1.5' stroke='currentColor' className='w-5 h-5'>
                <path strokeLinecap='round' strokeLinejoin='round' d='M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z' />
            </svg>
            <input {...inputProps} />
        </div>
    )
}
