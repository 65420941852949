import { FC, forwardRef, RefObject, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { Preset, Visibility } from 'matrix-js-sdk'

import { AvatarData, useMatrix } from '@closer/headless-components/hooks'
import { ContactListItemProps, SyncedContact } from '@closer/headless-components/components/ContactSync'
import { Avatar, AvatarProps } from '../Common/Avatar'

export const ContactListItem: FC<ContactListItemProps> = ({ contact, disabled, roomInfo, onPress }) => {
    const avatarRef = useRef<Pick<AvatarData, 'url'>>(null)
    const itemProps = {
        className: 'flex flex-row p-2',
        onClick: async () => {
            if (disabled) {
                return
            }
            if (roomInfo) {
                onPress && onPress({ contact, avatarUrl: avatarRef.current?.url })
            } else {
                onPress &&
                    onPress({
                        contact,
                        avatarUrl: avatarRef.current?.url,
                        newRoomArg: {
                            invite: [contact.matrixId],
                            visibility: Visibility.Private,
                            preset: Preset.TrustedPrivateChat,
                            is_direct: true,
                            initial_state: [
                                {
                                    type: 'm.room.name',
                                    content: {
                                        name: contact.fullName
                                    }
                                },
                                {
                                    type: 'm.room.avatar',
                                    content: {
                                        url: avatarRef.current?.url
                                    }
                                }
                            ]
                        }
                    })
            }
        }
    }
    return (
        <div {...itemProps}>
            <ContactAvatar {...contact} ref={avatarRef} />
            <div className='ml-2 flex items-center'>{contact.fullName}</div>
        </div>
    )
}

const ContactAvatar = forwardRef<Pick<AvatarData, 'url'> | undefined, SyncedContact>((props, ref) => {
    const avatarRef = useRef<HTMLDivElement>(null)
    const isVisible = useIsVisible(avatarRef)
    const [avatarData, setAvatarData] = useState<AvatarProps>({ imageUrl: undefined, name: props.fullName })
    const { client } = useMatrix()
    const { matrixId } = props

    useEffect(() => {
        if (isVisible && !avatarData.imageUrl) {
            client?.getProfileInfo(matrixId).then(({ avatar_url }) => {
                const imageUrl = avatar_url && client.mxcUrlToHttp(avatar_url, 50, 50)
                setAvatarData({
                    imageUrl: imageUrl === null ? undefined : imageUrl,
                    name: props.fullName
                })
            })
        }
    }, [avatarData.imageUrl, client, isVisible, matrixId, props.fullName])

    useImperativeHandle(ref, () => ({ url: avatarData.imageUrl }))

    return (
        <div ref={avatarRef}>
            <Avatar {...avatarData} />
        </div>
    )
})

export function useIsVisible(ref: RefObject<HTMLDivElement>) {
    const [isIntersecting, setIntersecting] = useState(false)

    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting))

        ref.current && observer.observe(ref.current)
        return () => {
            observer.disconnect()
        }
    }, [ref])

    return isIntersecting
}
