import { ComponentProps, FC, useMemo } from 'react'

import { useMatrix } from '@closer/headless-components/hooks'

import { mdUserEdit, Svg } from '../Common'

export const UserInfo: FC = () => {
    const { client } = useMatrix()
    const userId = useMemo(() => (client?.getUserId() || '').replace(/^@/, '').split(':')[0], [client])
    const editProps: ComponentProps<'button'> = {
        onClick: () => null
    }

    return (
        <div>
            <div className='grid grid-cols-[1fr,min-content] pb-1 border-b'>
                <h2 className='text-xl font-semibold'>User Information</h2>
                <button {...editProps}>
                    <Svg d={mdUserEdit} fill='currentColor' className='w-6 h-6 text-neutral-200' />
                </button>
            </div>
            <p className='col-span-2 py-2 text-sm'>Username: {userId}</p>
        </div>
    )
}
